<template>
  <!-- File Preview Section -->
  <v-col :cols="$vuetify.breakpoint.lgAndUp ? 4 : 12" class="mt-10">
    <div class="d-flex justify-space-between align-center mr-4">
      <p class="ma-0 pa-0 fs-18 fw-600 f-inter" style="letter-spacing: -0.3px">
        File Preview
      </p>
      <v-btn
        class="btn btn-primary primary--text text-capitalize"
        color="lightBackground"
        depressed
        :loading="combinePDFLoader"
        @click="addNewPdf()"
      >
        <span class="fw-600 fs-14 f-inter" style="letter-spacing: -0.3px">
          Combine pages
        </span>
      </v-btn>
    </div>

    <!-- Page Information -->
    <div class="d-flex justify-space-between align-center mt-3 mr-4">
      <p
        class="ma-0 pa-0 fs-12 fw-500 f-inter subHeading--text"
        style="letter-spacing: -0.3px"
        v-for="(item4, index4) in pageInformation"
        :key="index4"
      >
        <span
          v-if="item4.id == 1"
          style="cursor: pointer"
          @click="combinePDFintoOne()"
          >{{ combinePDF.length === 0 ? item4.key : "Unselect All" }}
          {{ evaluateExpression(item4.value) }}
        </span>
        <span v-else
          >{{ item4.key }} {{ evaluateExpression(item4.value) }}</span
        >
      </p>
    </div>

    <!-- PDF Previews -->
    <div v-if="newFileAddedCheck" style="max-height: 80vh; overflow-y: scroll">
      <!-- Selected PDF Pages -->
      <div
        v-for="(item1, index1) in Object.keys(selectedValueUpdated)"
        :key="'A ' + index1"
        class="pdfBorderDesignSelected py-2 my-2 mr-3"
      >
        <v-container>
          <div
            v-for="(item2, index2) in selectedValueUpdated[item1]"
            :key="'B' + index2"
            class="ma-2"
          >
            <div class="d-flex justify-space-between align-center">
              <p class="ma-0 pa-0 fs-18 f-inter" style="letter-spacing: -0.3px">
                Page {{ item2 }}
              </p>
              <v-btn
                icon
                color="primary"
                @click="changeValue(item1, item2, false)"
                :ripple="false"
              >
                <v-icon dense :ripple="false">mdi-checkbox-marked</v-icon>
              </v-btn>
            </div>
            <VuePdfEmbed
              :source="pdfDialog.pdfBytes"
              :page="item2"
              height="350"
              width="320"
            />
            <!-- width="330" -->
          </div>
        </v-container>
      </div>

      <!-- Unselected PDF Pages -->
      <div v-for="(item3, index3) in pdfDialog.totalPages" :key="'C ' + index3">
        <div
          v-if="!addedPdf.includes(item3)"
          class="pdfBorderDesign py-2 my-2 pr-2 mr-3"
        >
          <v-container>
            <div class="d-flex justify-space-between align-center">
              <p class="ma-0 pa-0 fs-18 f-inter" style="letter-spacing: -0.3px">
                Page {{ item3 }}
              </p>
              <v-btn
                icon
                color="primary"
                v-if="combinePDF.includes(item3)"
                @click="combinePDFCheck(item3, false)"
                :ripple="false"
              >
                <v-icon dense :ripple="false">mdi-checkbox-marked</v-icon>
              </v-btn>
              <v-btn
                icon
                color="primary"
                :ripple="false"
                v-else
                @click="combinePDFCheck(item3, true)"
              >
                <v-icon dense :ripple="false"
                  >mdi-checkbox-blank-outline</v-icon
                >
              </v-btn>
            </div>
            <VuePdfEmbed
              :source="pdfDialog.pdfBytes"
              :page="item3"
              height="350"
              width="320"
            />
          </v-container>
        </div>
      </div>
    </div>

    <!-- Skeleton Loader -->
    <v-skeleton-loader v-else type="article, list-item"></v-skeleton-loader>
  </v-col>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import Vue from "vue";
export default {
  components: { VuePdfEmbed },
  data() {
    return {
      combinePDF: [],
      addedPdf: [],
      combinePDFLoader: false,
      selectedValueUpdated: {},
      newFileAddedCheck: true,
      pageInformation: [
        {
          id: 0,
          key: "Total Pages",
          value: "this.pdfDialog.totalPages",
        },
        {
          id: 1,
          key: "Select All",
          value: "",
        },
        {
          id: 2,
          key: "Requests",
          value:
            "Object.keys(this.selectedValueUpdated).length + this.pdfDialog.totalPages - this.addedPdf.length",
        },
        {
          id: 3,
          key: "Selected",
          value: "this.combinePDF.length",
        },
      ],
    };
  },
  props: {
    pdfDialog: Object,
  },
  mounted() {
    /*
     ** Code to execute after the component is mounted
     */
  },
  computed: {},
  methods: {
    /*
     ** Evaluate Expression Using String Value
     */
    evaluateExpression(expression) {
      return eval(expression);
    },
    /*
     ** Combine all PDF pages into a single selection
     */
    combinePDFintoOne() {
      if (this.combinePDF.length !== 0) return (this.combinePDF = []);
      this.combinePDF = [];
      for (let i = 1; i <= this.pdfDialog.totalPages; i++) {
        if (this.addedPdf.includes(i)) continue;
        this.combinePDF.push(i);
      }
      // this.addNewPdf();
    },
    /*
     ** Add selected PDF pages to the list
     */
    addNewPdf() {
      /*
       ** Start loaders
       */
      (this.newFileAddedCheck = false), (this.combinePDFLoader = true);
      const length = Object.keys(this.selectedValueUpdated).length;
      /*
       ** Validations
       */
      if (this.combinePDF.length === 0)
        return this.errorValidationsForAddingNewPdf(
          "error",
          "No file has been selected"
        );
      else if (this.combinePDF.length === 1)
        return this.errorValidationsForAddingNewPdf(
          "error",
          "Must select more than 1 Page."
        );
      if (length > this.pdfDialog.PDF_CREATION_LIMIT)
        return this.errorValidationsForAddingNewPdf(
          "error",
          `Only ${
            this.pdfDialog.PDF_CREATION_LIMIT + 1
          } can be created from pdf`
        );
      /*
       ** Update selected Value Updated and Manage Remaining Pages
       */
      Vue.set(this.selectedValueUpdated, length.toString(), this.combinePDF);
      this.addedPdf = [...new Set([...this.addedPdf, ...this.combinePDF])];
      /*
       ** Reset combine PDF Data
       */
      this.combinePDF = [];
      this.$emit("selected-value", this.selectedValueUpdated);
      /*
       ** Show PDF Preview and Close Loaders
       */
      this.showPdfPreview(true, false);
    },
    /*
     ** Show Pdf Previews
     */
    showPdfPreview(newFileCheck, pdfLoader) {
      /*
       ** Show Pdf after 1 second as currently page is rendering and our npm package vue-pdf-embed isn't updating regularly
       */
      setInterval(() => {
        this.newFileAddedCheck = newFileCheck;
        this.combinePDFLoader = pdfLoader;
      }, 1000);
    },
    /*
     ** Handle errors for while adding new Pdf
     */
    errorValidationsForAddingNewPdf(type, message) {
      this.newFileAddedCheck = true;
      this.combinePDFLoader = false;
      this.$showNotificationBar(type, message);
    },
    /*
     ** Change the value of selected PDF pages
     */
    changeValue(key, pageNo, addPageNo) {
      /*
       ** Check for adding Page No (Currently we are adding page from different function)
       */
      if (addPageNo) return this.selectedValueUpdated[key].push(pageNo);
      /*
       ** Remove page from selected file
       */
      this.newFileAddedCheck = false;
      let index = this.selectedValueUpdated[key].findIndex(
        (value) => value === pageNo
      );
      /*
       ** Handle Validations
       */
      if (index === -1)
        return this.errorValidationsForAddingNewPdf(
          "error",
          "No page found in selected PDF"
        );
      /*
       ** Remove page from pdf , show pdf preview and sort pages
       */
      this.selectedValueUpdated[key].splice(index, 1);
      this.selectedValueUpdated[key].sort();
      this.showPdfPreview(true, false);
      /*
       ** Update Remaining Pages in variable
       */
      this.updateRemainingPages(pageNo);

      if (this.selectedValueUpdated[key].length === 0)
        this.modifyObjectThatWillBeUsedForCreation();
      this.$emit("selected-value", this.selectedValueUpdated);
    },
    /*
     ** Update the remaining pages when a page is deselected
     */
    updateRemainingPages(pageNo) {
      let index = this.addedPdf.findIndex((index) => index === pageNo);
      this.addedPdf.splice(index, 1);
    },
    /*
     ** Modify the object used for PDF creation
     */
    modifyObjectThatWillBeUsedForCreation() {
      let indexValue = 0;
      let newObj = {};
      for (let i = 0; i < Object.keys(this.selectedValueUpdated).length; i++) {
        if (this.selectedValueUpdated[i.toString()].length !== 0) {
          newObj[indexValue.toString()] =
            this.selectedValueUpdated[i.toString()];
          indexValue = indexValue + 1;
        }
      }
      this.selectedValueUpdated = JSON.parse(JSON.stringify(newObj));
      this.showPdfPreview(true, false);
    },
    /*
     ** Check/uncheck the combinePDF array
     */
    combinePDFCheck(item, check) {
      if (check) {
        this.combinePDF.push(item);
        this.combinePDF.sort();
      } else {
        let index = this.combinePDF.findIndex((index) => index === item);
        if (index > -1) this.combinePDF.splice(index, 1);
      }
    },
    /*
     ** Close the PDF dialog and reset variables
     */
    closePdfDialog() {
      (this.pdfDialog.show = false),
        (this.pdfDialog.createMultipleTicketsLoader = false);
      this.pdfDialog.totalPages = 0;
      this.pdfDialog.pdfDoc = null;
      this.loader = false;
      this.selectedValueUpdated = JSON.parse(JSON.stringify({}));
    },
  },
  beforeDestroy() {},
};
</script>
<style scoped>
.pdfBorderDesign {
  border-width: 1.43px;
  border-color: var(--v-borderColor-base);
  border-style: solid;
  border-radius: 5px;
}
.pdfBorderDesignSelected {
  border-width: 1.43px;
  border-color: var(--v-primary-base);
  border-style: solid;
  border-radius: 5px;
}
</style>
<style></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"app":""}},[_c('v-app-bar',{staticClass:"white",attrs:{"app":"","flat":""}},[_c('v-toolbar-title',{staticClass:"d-flex"},[_c('span',{staticClass:"material-icons lightPurple--text",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_vm._v(" menu ")]),_c('v-navigation-drawer',{staticStyle:{"z-index":"999 !important"},attrs:{"absolute":"","temporary":"","height":"100vh"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-img',{staticClass:"pa-0 ma-0",attrs:{"src":require('@/assets/dummy-workspace.svg')}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"fs-14 fw-400 text-capitalize"},[_vm._v(_vm._s(_vm.user.name))])],1)],1),_c('v-divider'),_vm._l((_vm.items),function(item){return _c('v-list',{key:item.title,staticClass:"my-0 py-0",attrs:{"dense":""}},[(!item.auth || _vm.user.role === 'client')?_c('router-link',{staticClass:"remove-underline text-capitalize",class:_vm.$route.name == item.routeName ? 'primary--text' : 'heading-text',attrs:{"to":item.to}},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-icon',[_c('v-icon',{class:_vm.$route.name == item.routeName
                      ? 'primary--text'
                      : 'heading-text'},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:_vm.$route.name == item.routeName
                      ? 'primary--text'
                      : 'heading-text'},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)],1):_vm._e()],1)}),_c('v-divider'),_vm._l((_vm.secondList),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',{on:{"click":function($event){item.to == '/logout' ? _vm.logout() : ''}}},[_c('v-list-item-title',[(!item.auth || _vm.user.role === 'client')?_c('router-link',{staticClass:"remove-underline",class:_vm.$route.name == item.routeName
                    ? 'primary--text'
                    : 'heading-text',attrs:{"to":item.to}},[_vm._v(_vm._s(item.title)+" ")]):_vm._e()],1)],1)],1)})],2)],1),_c('v-spacer'),_c('MobileHeaderFilters'),(_vm.role === 'client')?_c('NotificationDropDown',{staticClass:"mb-3"}):_vm._e(),_c('div',{staticClass:"d-flex whiteColor mobile-workspace-spacing"},[_c('v-avatar',{staticClass:"white--text workspace-default-img",attrs:{"color":"#7147F6","size":"26","tile":""}},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.activeWorkspace?.workspaceName[0].toUpperCase(),10)))]),_c('HeaderWorkSpaceDropdown')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
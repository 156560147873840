import { render, staticRenderFns } from "./NoWorkspace.vue?vue&type=template&id=9865b752&scoped=true&"
import script from "./NoWorkspace.vue?vue&type=script&lang=js&"
export * from "./NoWorkspace.vue?vue&type=script&lang=js&"
import style0 from "./NoWorkspace.vue?vue&type=style&index=0&id=9865b752&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9865b752",
  null
  
)

export default component.exports
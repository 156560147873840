<template>
  <v-container :class="$vuetify.breakpoint.lgAndUp ? 'ma-0 pa-0 ml-4' : 'mx-8'">
    <div>
      <span class="fw-600 fs-18 f-inter ml-2"> Banks </span>

      <div class="bank-main mt-3">
        <div v-for="(bank, index) in banks.rows" :key="index">
          <div
            v-if="bank.rowType != 'SummaryRow'"
            class="bank-card pa-4 dashboardBlockColor custom-dashboard-border"
          >
            <div class="d-flex justify-space-between">
              <div>
                <div class="fw-400 fs-14 f-inter">Closing balance</div>
                <div class="fs-22 fw-600">
                  {{ closingBalance[index]?.value }}
                </div>
              </div>

              <div class="bank-chip d-flex">
                <img src="../../assets/dashboard/bank.svg" alt="" />
                <span class="fw-500 fs-10 f-inter ml-1 ellipsis-text">
                  {{ bank?.cells[0]?.value }}</span
                >
              </div>
            </div>
            <div class="d-flex justify-space-between">
              <div class="d-flex flex-column fs-12 fw-500 f-inter">
                <span> Opening balance </span>
                <span class="fs-12 fw-700">{{ bank?.cells[1]?.value }}</span>
              </div>
              <!-- <div class="bank-chip d-flex" v-if="reconciles[index].data"> -->
              <!-- <img src="../../assets/dashboard/bank.svg" alt="" /> -->
              <!-- <span class="fw-500 fs-10 f-inter ml-1 ellipsis-text">
                  Reconcile</span
                > -->
              <!-- </div> -->
            </div>
          </div>
          <!-- <div v-else>
            <div class="bank-main mt-3">
              <div
                class="bank-card pa-4 d-flex justify-center align-center dashboardBlockColor custom-dashboard-border"
              >
                <div class="fw-600 fs-14 f-inter textPrimary--text">
                  No banks
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- <div v-else>
        <div class="bank-main mt-3">
          <div
            class="bank-card pa-4 d-flex justify-center align-center dashboardBlockColor custom-dashboard-border"
          >
            <div class="fw-600 fs-14 f-inter textPrimary--text">No banks</div>
          </div>
        </div>
      </div> -->
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import ApiService from "../../services/Api";
export default {
  props: {
    toDate: {
      default: "",
    },
  },
  data() {
    return {
      banks: [],
      closingBalance: [],
      reconciles: [],
      reports: [
        {
          reportID: "BankSummary",
          reportName: "Bank Summary",
          reportType: "BankSummary",
          reportTitles: [
            "Bank Summary",
            "ABS Test",
            "From 1 September 2023 to 30 September 2023",
          ],
          reportDate: "21 September 2023",
          rows: [
            {
              rowType: "Header",
              cells: [
                {
                  value: "Bank Accounts",
                },
                {
                  value: "Opening Balance",
                },
                {
                  value: "Cash Received",
                },
                {
                  value: "Cash Spent",
                },
                {
                  value: "FX Gain",
                },
                {
                  value: "Closing Balance",
                },
              ],
            },
            {
              rowType: "Section",
              title: "",
              rows: [
                {
                  rowType: "Row",
                  cells: [
                    {
                      value: "Wise",
                      attributes: [
                        {
                          id: "accountID",
                          value: "82cc01a3-9058-4a5e-8488-0a0d8aa32d05",
                        },
                      ],
                    },
                    {
                      value: "-1449723.55",
                    },
                    {
                      value: "0.00",
                      attributes: [
                        {
                          id: "account",
                          value: "82cc01a3-9058-4a5e-8488-0a0d8aa32d05",
                        },
                      ],
                    },
                    {
                      value: "0.00",
                      attributes: [
                        {
                          id: "account",
                          value: "82cc01a3-9058-4a5e-8488-0a0d8aa32d05",
                        },
                      ],
                    },
                    {
                      value: "0.00",
                    },
                    {
                      value: "-1449723.55",
                    },
                  ],
                },
                {
                  rowType: "Row",
                  cells: [
                    {
                      value: "Wise EURO",
                      attributes: [
                        {
                          id: "accountID",
                          value: "8991cdc3-98da-4c4e-adb4-1e2a8ec481b4",
                        },
                      ],
                    },
                    {
                      value: "99696.92",
                    },
                    {
                      value: "0.00",
                      attributes: [
                        {
                          id: "account",
                          value: "8991cdc3-98da-4c4e-adb4-1e2a8ec481b4",
                        },
                      ],
                    },
                    {
                      value: "0.00",
                      attributes: [
                        {
                          id: "account",
                          value: "8991cdc3-98da-4c4e-adb4-1e2a8ec481b4",
                        },
                      ],
                    },
                    {
                      value: "-1900.31",
                    },
                    {
                      value: "97796.61",
                    },
                  ],
                },
                {
                  rowType: "Row",
                  cells: [
                    {
                      value: "Wise GBP",
                      attributes: [
                        {
                          id: "accountID",
                          value: "501cc546-df5a-444f-bdb1-c39f0f3d8b31",
                        },
                      ],
                    },
                    {
                      value: "3079383.45",
                    },
                    {
                      value: "0.00",
                      attributes: [
                        {
                          id: "account",
                          value: "501cc546-df5a-444f-bdb1-c39f0f3d8b31",
                        },
                      ],
                    },
                    {
                      value: "0.00",
                      attributes: [
                        {
                          id: "account",
                          value: "501cc546-df5a-444f-bdb1-c39f0f3d8b31",
                        },
                      ],
                    },
                    {
                      value: "-98457.73",
                    },
                    {
                      value: "2980925.72",
                    },
                  ],
                },
                {
                  rowType: "SummaryRow",
                  cells: [
                    {
                      value: "Total",
                    },
                    {
                      value: "1729356.82",
                    },
                    {
                      value: "0.00",
                    },
                    {
                      value: "0.00",
                    },
                    {
                      value: "-100358.04",
                    },
                    {
                      value: "1628998.78",
                    },
                  ],
                },
              ],
            },
          ],
          updatedDateUTC: "2023-09-21T14:43:44.450Z",
          fields: [],
        },
      ],

      bankReports: [
        {
          reportID: "BankSummary",
          reportName: "Bank Summary",
          reportType: "BankSummary",
          reportTitles: [
            "Bank Summary",
            "ABS Test",
            "From 1 September 2023 to 30 September 2023",
          ],
          reportDate: "22 September 2023",
          rows: [
            {
              rowType: "Header",
              cells: [
                {
                  value: "Bank Accounts",
                },
                {
                  value: "Opening Balance",
                },
                {
                  value: "Cash Received",
                },
                {
                  value: "Cash Spent",
                },
                {
                  value: "FX Gain",
                },
                {
                  value: "Closing Balance",
                },
              ],
            },
            {
              rowType: "Section",
              title: "",
              rows: [
                {
                  rowType: "Row",
                  cells: [
                    {
                      value: "Wise",
                      attributes: [
                        {
                          id: "accountID",
                          value: "82cc01a3-9058-4a5e-8488-0a0d8aa32d05",
                        },
                      ],
                    },
                    {
                      value: "-1449723.55",
                    },
                    {
                      value: "0.00",
                      attributes: [
                        {
                          id: "account",
                          value: "82cc01a3-9058-4a5e-8488-0a0d8aa32d05",
                        },
                      ],
                    },
                    {
                      value: "0.00",
                      attributes: [
                        {
                          id: "account",
                          value: "82cc01a3-9058-4a5e-8488-0a0d8aa32d05",
                        },
                      ],
                    },
                    {
                      value: "0.00",
                    },
                    {
                      value: "-1449723.55",
                    },
                  ],
                },
                {
                  rowType: "Row",
                  cells: [
                    {
                      value: "Wise EURO",
                      attributes: [
                        {
                          id: "accountID",
                          value: "8991cdc3-98da-4c4e-adb4-1e2a8ec481b4",
                        },
                      ],
                    },
                    {
                      value: "99696.92",
                    },
                    {
                      value: "0.00",
                      attributes: [
                        {
                          id: "account",
                          value: "8991cdc3-98da-4c4e-adb4-1e2a8ec481b4",
                        },
                      ],
                    },
                    {
                      value: "0.00",
                      attributes: [
                        {
                          id: "account",
                          value: "8991cdc3-98da-4c4e-adb4-1e2a8ec481b4",
                        },
                      ],
                    },
                    {
                      value: "-1777.45",
                    },
                    {
                      value: "97919.47",
                    },
                  ],
                },
                {
                  rowType: "Row",
                  cells: [
                    {
                      value: "Wise GBP",
                      attributes: [
                        {
                          id: "accountID",
                          value: "501cc546-df5a-444f-bdb1-c39f0f3d8b31",
                        },
                      ],
                    },
                    {
                      value: "3079383.45",
                    },
                    {
                      value: "0.00",
                      attributes: [
                        {
                          id: "account",
                          value: "501cc546-df5a-444f-bdb1-c39f0f3d8b31",
                        },
                      ],
                    },
                    {
                      value: "0.00",
                      attributes: [
                        {
                          id: "account",
                          value: "501cc546-df5a-444f-bdb1-c39f0f3d8b31",
                        },
                      ],
                    },
                    {
                      value: "-93404.40",
                    },
                    {
                      value: "2985979.05",
                    },
                  ],
                },
                {
                  rowType: "SummaryRow",
                  cells: [
                    {
                      value: "Total",
                    },
                    {
                      value: "1729356.82",
                    },
                    {
                      value: "0.00",
                    },
                    {
                      value: "0.00",
                    },
                    {
                      value: "-95181.85",
                    },
                    {
                      value: "1634174.97",
                    },
                  ],
                },
              ],
            },
          ],
          updatedDateUTC: "2023-09-22T05:21:52.019Z",
          fields: [],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
      user: "auth/getUser",
    }),
    currentDate() {
      const date = new Date(); // get the current date and time
      const formattedDate = moment(date).format("YYYY-MM-DD");
      return formattedDate;
    },
  },
  mounted() {
    // this.getOpeningBalance();
    this.banks = this.bankReports[0].rows[1];
    console.log(this.reports, "REp");
    let closingBalance = this.reports[0].rows[1].rows;
    let values = [];
    let rows = [];
    let balance = [];
    let accounts = [];
    let bankAccounts = [];
    let attributes = [];
    let accountIds = [];
    closingBalance.map((val) => {
      values.push(val);
    });
    values.map((bal) => {
      rows.push(bal.cells);
    });
    rows.map((bal) => {
      balance.push(bal[1]);
      accounts.push(bal[0]);
    });
    rows.map((accounts) => {
      bankAccounts.push(accounts[0]);
    });
    bankAccounts.map((attr) => {
      attributes.push(attr.attributes);
    });
    attributes.map((val) => {
      accountIds.push(val);
    });
    console.log(this.reconciles);
    this.closingBalance = balance;
  },

  methods: {
    getOpeningBalance() {
      let dateUrl = `?from=${
        this.toDate == "" ? this.currentDate : this.toDate
      }&to=${this.currentDate}`;
      ApiService.GetRequest(
        `xero/bank-summary/${this.currentWorkspace.workspaceId}${
          this.toDate == "" ? "" : dateUrl
        }`
      )
        .then((res) => {
          this.banks = res.data.reports[0].rows[1];
          console.log(this.banks, "bankssss");
        })
        .catch((err) => {
          console.log(err);
        });
      this.getClosingBalance();
    },
    getClosingBalance() {
      ApiService.GetRequest(
        `xero/bank-summary/${this.currentWorkspace.workspaceId}?from=${this.currentDate}&to=${this.currentDate}`
      )
        .then((res) => {
          let closingBalance = res.data.reports[0].rows[1].rows;
          let values = [];
          let rows = [];
          let balance = [];
          let accounts = [];
          let bankAccounts = [];
          let attributes = [];
          let accountIds = [];
          closingBalance.map((val) => {
            values.push(val);
          });
          // console.log(values, "vallll");
          values.map((bal) => {
            rows.push(bal.cells);
          });
          rows.map((bal) => {
            // console.log(bal, "balllll");
            balance.push(bal[1]);
            accounts.push(bal[0]);
          });
          rows.map((accounts) => {
            // console.log(bal, "balllll");

            bankAccounts.push(accounts[0]);
          });
          bankAccounts.map((attr) => {
            attributes.push(attr.attributes);
          });
          attributes.map((val) => {
            accountIds.push(val);
          });

          // accountIds = accountIds.filter((item) => item !== undefined);
          // console.log(accountIds, "accounts arr");
          // accountIds.forEach((item) => {
          //   console.log(item[0].value);
          //   let ids = item[0].value;
          //   ApiService.GetRequest(
          //     `/xero/unreconciled-transactions/${this.currentWorkspace.workspaceId}?accountID=${ids}`
          //   ).then((res) => {
          //     let data = res.data.unreconcile;
          //     console.log(res.data.unreconcile);
          //     this.reconciles.push({
          //       id: ids,
          //       data: data,
          //     });
          //   });
          // });
          console.log(this.reconciles);
          this.closingBalance = balance;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.bank-main {
  max-height: 395px;
  overflow-y: auto;
}
.bank-card {
  height: 117px !important;
  width: 90%;
  border: 1px solid var(--v-inputBackground-base);
  background: var(--v-inputBackground-base);
  border-radius: 6.28px;
  margin-bottom: 7px;
}

.bank-chip {
  height: 32px;
  width: 112px;
  margin-top: 8px;
  background: var(--v-lightBackground-base);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.ellipsis-text {
  margin: 0px;
  white-space: nowrap !important;
  width: 60px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.custom-dashboard-border {
  border: 1px solid var(--v-dashboardBlockBorderColor-base) !important;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4",style:(_vm.$vuetify.breakpoint.lgAndUp
      ? 'padding: 60px 0px 0px !important'
      : 'padding: 10px 0px 0px !important')},[(_vm.currentWorkspaceBoolean)?_c('DashboardHeader'):_vm._e(),(_vm.$route.name == 'Dashboard' && _vm.currentWorkspaceBoolean)?_c('v-row',{staticClass:"mb-0 mx-6",class:_vm.$vuetify.breakpoint.lgAndUp ? 'mt-0' : 'mt-15'},[_c('v-col',{staticClass:"pt-0 mb-0 pb-0 ma-0 d-flex justify-space-between",attrs:{"md":"6","cols":"12","sm":"12"}},[_c('p',{staticClass:"black--text fs-20 fw-600 f-inter mb-0 pb-0 cursor-pointer mt-3"},[_vm._v(" Dashboard ")]),(!_vm.$vuetify.breakpoint.lgAndUp)?_c('p',{staticClass:"mt-1 pt-0 mb-0 pb-0"},[_c('v-btn',{class:_vm.$vuetify.breakpoint.lgAndUp
              ? 'create-btn mt-1 fs-20 fw-600'
              : 'create-btn-mobile mt-1',attrs:{"large":""},on:{"click":function($event){return _vm.$router.push({ path: 'generate-request' })}}},[_c('v-img',{attrs:{"src":require("@/assets/ticket/receipt.svg")}}),_vm._v("Record Now")],1)],1):_vm._e()]),_c('v-col',{class:_vm.$vuetify.breakpoint.lgAndUp
          ? 'pt-0 mb-0 pb-0 ma-0 mt-2 d-flex justify-end'
          : 'pt-0',attrs:{"md":"6","cols":"12","sm":"12"}},[(_vm.$vuetify.breakpoint.lgAndUp && _vm.clearFilterData)?_c('p',{staticClass:"black--text fs-14 fw-700 mb-0 pb-0 cursor-pointer mt-3 mr-10",on:{"click":function($event){return _vm.clearFilter()}}},[_vm._v(" Clear Filter ")]):_vm._e(),_c('p',{staticClass:"mt-0 pt-0 mb-0 pb-0"},[_c('v-menu',{ref:"menu",staticClass:"mt-0 pt-0",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","left":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"border-radius-7 custom-border-color",class:_vm.$vuetify.breakpoint.lgAndUp
                  ? 'mt-0 pt-0 pr-0 custom-data-width'
                  : 'mt-3 w-100',attrs:{"value":_vm.date,"label":"Select Month","dense":"","outlined":"","disabled":"","hide-details":_vm.$vuetify.breakpoint.lgAndUp ? false : true},on:{"click":function($event){_vm.menu = true}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(!_vm.$vuetify.breakpoint.lgAndUp && _vm.clearFilterData)?_c('span',{staticClass:"material-icons cursor-pointer",on:{"click":function($event){return _vm.clearFilter()}}},[_vm._v(" close ")]):_c('span',{staticClass:"material-icons cursor-pointer",on:{"click":function($event){_vm.menu = true}}},[_vm._v(" event ")])]},proxy:true}],null,true),model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,1063224803),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","type":"month","show-current":true,"max":_vm.lastMonthOfCurrentYear,"color":"#6634E9"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":() => {
                  _vm.menu = false;
                  _vm.date = '';
                }}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":_vm.disabled},on:{"click":function($event){return _vm.dataRequest()}}},[_vm._v(" OK ")])],1)],1)],1),(_vm.$vuetify.breakpoint.lgAndUp)?_c('p',{staticClass:"mt-0 pt-0 mb-0 pb-0"}):_vm._e(),_c('div',{staticClass:"f-flex flex-row justify-end aling-center"},[_c('v-btn',{class:_vm.$vuetify.breakpoint.lgAndUp
              ? 'custom-btn f-inter'
              : 'create-btn-mobile f-inter',on:{"click":function($event){return _vm.$router.push({ path: 'generate-request' })}}},[_c('v-img',{attrs:{"src":require("@/assets/ticket/receipt.svg")}}),_c('span',{staticClass:"f-inter fs-12 fw-600 f-inter text-none ml-2",staticStyle:{"letter-spacing":"0 !important"}},[_vm._v("Record Now")])],1)],1),_c('p')])],1):_vm._e(),(!_vm.currentWorkspaceBoolean)?_c('NoWorkspace'):_vm._e(),(_vm.$route.name == 'Dashboard' && _vm.currentWorkspaceBoolean)?_c('DashboardStatics',{on:{"component-mounted":_vm.clearFilter}}):_vm._e(),_c('div',{staticClass:"d-flex flex-wrap"},[(_vm.$route.name == 'Dashboard' && _vm.currentWorkspaceBoolean)?_c('div',{style:(_vm.$vuetify.breakpoint.lgAndUp && !_vm.user.isEmployee
          ? 'width: 60%'
          : 'width: 97%')},[_c('DashboardChart')],1):_vm._e(),(
        _vm.user.role === 'client' &&
        _vm.$route.name == 'Dashboard' &&
        _vm.currentWorkspaceBoolean
      )?_c('div',{class:_vm.$vuetify.breakpoint.lgAndUp ? 'ml-3 mt-1' : 'ml-8 mt-8',style:(_vm.$vuetify.breakpoint.lgAndUp ? 'width: 37%' : 'width: 88%')},[_c('EmployeeStatus')],1):_vm._e()]),(_vm.dialogData)?_c('CustomDialog',{attrs:{"dialog":_vm.dialogData},on:{"updateInput":function($event){return _vm.updateInput($event)}}}):_vm._e(),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
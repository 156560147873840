<template>
  <div>
    <v-menu
      offset-y
      class=""
      :close-on-click="hideOnClick"
      :close-on-content-click="hideOnClick"
    >
      <!-- textfield to search start -->
      <!-- <template v-slot:activator="{ on, attrs }"> -->
      <template v-slot:activator="{}">
        <!-- v-bind="attrs" v-on="on" -->
        <v-img
          @click="dialog = true"
          class="mr-2"
          :src="require('@/assets/new-mobile-filter.svg')"
        ></v-img>
      </template>

      <v-list class="pa-0">
        <v-list-item class="ma-0">
          <v-list-item-content class="pa-0">
            <v-row justify="space-between" class="mt-1">
              <v-col md="6" sm="6" cols="6">
                <p class="fs-18 fw-600">Filters</p>
              </v-col>
              <v-col md="6" sm="6" cols="6" align="right">
                <p class="fs-14 fw-700">
                  <span
                    class="material-icons cursor-pointer custom-close-icon"
                    @click="hideOnClick = true"
                  >
                    close
                  </span>
                </p>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-divider dark color="#E7E8F4"></v-divider>
        <v-row>
          <v-col cols="12" md="12" class="mt-3 pb-0 mb-0">
            <FormInput
              ref="textField1"
              :input="input"
              v-for="input in messageInput"
              :key="`${input}-message`"
              @update-value="(n) => (input.model = n)"
            ></FormInput>
          </v-col>
          <v-col cols="12" md="12" class="mt-0 pt-0">
            <p class="fs-14 fw-600 pl-5 pt-0 mt-0 mb-0">Select Date Range</p>
          </v-col>

          <FormInput
            :input="input"
            v-for="(input, index) in inputList"
            :key="index"
            @update-date="setDate($event, value)"
          ></FormInput>

          <v-col
            cols="12"
            md="12"
            class="d-flex justify-space-between mt-0 pt-0 mb-0 pb-0"
          >
            <p class="fs-14 fw-600 pl-5 pt-2 pb-0 mb-0">Select Status Type</p>
            <p
              class="fs-10 fw-600 primary--text pr-5 pb-0 pt-2 mb-0 cursor-pointer"
              @click="selectAllCheckboxes(inputFilterStatusCheckbox)"
            >
              Select All
            </p>
          </v-col>

          <FormInput
            v-for="(input, index2) in inputFilterStatusCheckbox"
            :input="input"
            :key="`${index2}-2`"
            :selectedCheckboxes="inputFilterStatusCheckboxValues"
            @updateInput="updateInput($event, value)"
          ></FormInput>

          <v-col cols="12" md="12" class="d-flex justify-space-between">
            <p class="fs-14 fw-600 pl-5 pt-2 pb-0 mb-0">
              Select Generate Request Type
            </p>
            <p
              class="fs-10 fw-600 primary--text pr-5 pb-0 pt-2 mb-0 cursor-pointer"
              @click="
                selectAllRequestCheckboxes(inputFilterRequestTypeCheckbox)
              "
            >
              Select All
            </p>
          </v-col>

          <FormInput
            :input="input"
            v-for="(input, index3) in inputFilterRequestTypeCheckbox"
            :key="`${index3}-3`"
            :selected-checkboxes="inputFilterRequestTypeCheckboxValues"
            @updateInput="updateRequest($event, value)"
          ></FormInput>

          <v-col
            cols="12"
            md="12"
            class="mt-0 pt-3 pr-7 mb-0 pb-0"
            align="right"
          >
            <!-- <v-btn
            class="btn-primary secondary--text text-capitalize clear-btn mr-3"
            color="#FFFFFF"
            depressed
            width="108"
            height="38"
            @click="clearFilter()"
            ><span class="fw-600 fs-13">Clear Filter </span></v-btn
          > -->
            <v-btn
              class="btn-primary primary--text text-capitalize connect-btn"
              color="lightBackground"
              depressed
              width="108"
              height="38"
              @click="advancedSearch(), (hideOnClick = true)"
              ><span class="fw-600 fs-13">Apply Filter </span></v-btn
            >
          </v-col>
        </v-row>
      </v-list>
      <!-- menu items list after click on search end here -->
    </v-menu>

    <!-- dialog of filter start here -->

    <v-dialog
      v-model="dialog"
      width="400"
      class="bg-white"
      persistent
      scrollable
      v-if="!$vuetify.breakpoint.lgAndUp"
    >
      <v-card flat>
        <v-card-title
          class="d-flex justify-space-between text-heading pb-0 fixed"
          ><p class="fs-18 fw-600 heading-text">Advance Filters</p>
          <span class="material-icons custom-close-icon" @click="closeDialog">
            close
          </span>
        </v-card-title>
        <v-divider dark color="#E7E8F4"></v-divider>
        <v-card-text class="pa-0 ma-0" height="380">
          <v-row class="checkbox-row">
            <v-col cols="12" md="12" class="mt-3 pb-0 mb-0">
              <FormInput
                ref="textField1"
                :input="input"
                v-for="input in messageInput"
                :key="`${input}-message`"
                @update-value="(n) => (input.model = n)"
              ></FormInput>
            </v-col>
            <v-col cols="12" md="12" class="mt-0 pt-0">
              <p class="fs-14 fw-600 pl-5 pt-0 mt-0 mb-0">Select Date Range</p>
            </v-col>

            <FormInput
              :input="input"
              v-for="(input, index) in inputList"
              :key="index"
              @update-date="setDate($event, value)"
            ></FormInput>

            <v-col
              cols="12"
              md="12"
              class="d-flex justify-space-between mt-0 pt-0 mb-0 pb-0"
            >
              <p class="fs-14 fw-600 pl-5 pt-2 pb-0 mb-0">Select Status Type</p>
              <p
                v-if="!statusCheckboxClear"
                class="fs-10 fw-600 primary--text pr-5 pb-0 pt-2 mb-0 cursor-pointer"
                @click="selectAllCheckboxes(inputFilterStatusCheckbox)"
              >
                Select All
              </p>

              <p
                v-else
                class="fs-10 fw-600 primary--text pr-5 pb-0 pt-2 mb-0 cursor-pointer"
                @click="clearCheckboxes('status')"
              >
                Unselect All
              </p>
            </v-col>

            <FormInput
              v-for="(input, index2) in inputFilterStatusCheckbox"
              :input="input"
              :key="`${index2}-2`"
              :selectedCheckboxes="inputFilterStatusCheckboxValues"
              @updateInput="updateInput($event, value)"
            ></FormInput>

            <v-col cols="12" md="12" class="d-flex justify-space-between">
              <p class="fs-14 fw-600 pl-5 pt-2 pb-0 mb-0">
                Select Generate Request Type
              </p>
              <p
                v-if="!requestCheckboxClear"
                class="fs-10 fw-600 primary--text pr-5 pb-0 pt-2 mb-0 cursor-pointer"
                @click="
                  selectAllRequestCheckboxes(inputFilterRequestTypeCheckbox)
                "
              >
                Select All
              </p>
              <p
                v-else
                class="fs-10 fw-600 primary--text pr-5 pb-0 pt-2 mb-0 cursor-pointer"
                @click="clearCheckboxes('request')"
              >
                Unselect All
              </p>
            </v-col>

            <FormInput
              :input="input"
              v-for="(input, index3) in inputFilterRequestTypeCheckbox"
              :key="`${index3}-3`"
              :selected-checkboxes="inputFilterRequestTypeCheckboxValues"
              @updateInput="updateRequest($event, value)"
            ></FormInput>

            <v-col
              cols="12"
              md="12"
              class="mt-0 pt-3 pr-7 mb-1 pb-1"
              align="right"
            >
              <!-- <v-btn
            class="btn-primary secondary--text text-capitalize clear-btn mr-3"
            color="#FFFFFF"
            depressed
            width="108"
            height="38"
            @click="clearFilter()"
            ><span class="fw-600 fs-13">Clear Filter </span></v-btn
          > -->
              <v-btn
                class="btn-primary primary--text text-capitalize connect-btn"
                color="lightBackground"
                depressed
                width="108"
                height="38"
                @click="advancedSearch(), (hideOnClick = true)"
                ><span class="fw-600 fs-13">Apply Filter </span></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FormInput from "../../../common/FormInput.vue";
import axios from "axios";
import moment from "moment";
import UrlServices from "@/services/Url";
import { mapGetters } from "vuex";
import EventBus from "../../../../js/EventBus";

export default {
  name: "HeaderSearchBar",
  // watch:{
  //   isOpened: function(){
  //     this.hideOnClick ? this.hideOnClick=false : this.hideOnClick=true;
  //   }
  // },
  components: { FormInput },
  data: () => ({
    hideOnClick: false,
    searchValue: null,
    dateRangeText: "",
    dates: "",
    messageInput: [
      {
        name: "message",
        label: "Description",
        placeholder: "Search From Description",
        type: "text",
        cols: 12,
        col_md: 12,
        model: "",
      },
    ],

    /**
     * Desc:This is dynamic list to show inputs of dates in filters
     * showIcon:this prop is use to show eye icon of password field
     *  * cols: This prop is used to make column of row dynamic
     * col_md: what should be the column in a row when page is open on medium screen
     */
    inputList: [
      {
        name: "date_range",
        label: "Date Range",
        placeholder: "Select Date",
        type: "date",
        range: "range",
        cols: 12,
        col_md: 12,
        class: "pl-7 pr-7 pt-0 mb-0 pb-0",
        model: "",
        showDateFormat: "",
      },
    ],
    /**
     * Desc:This is dynamic list to show inputs of status checkboxes
     */
    inputFilterStatusCheckboxValues: [],
    inputFilterStatusCheckbox: [
      {
        name: "pending",
        label: "Pending",
        placeholder: "",
        type: "checkbox",
        cols: 6,
        col_md: 4,
        value: false,
        class: "pl-7 pr-7 pt-0 mb-0 pb-0 mt-0",
        text: "Pending",
      },
      {
        name: "in_progress",
        label: "InProgress",
        placeholder: "",
        type: "checkbox",
        cols: 6,
        col_md: 4,
        value: false,
        class: "pl-7 pr-7 pt-0 mb-0 pb-0 mt-0",
        text: "In Progress",
      },
      {
        name: "awaiting_response",
        label: "AwaitingResponse",
        placeholder: "",
        type: "checkbox",
        cols: 6,
        col_md: 4,
        value: false,
        class: "pl-7 pr-7 pt-0 mb-0 pb-0 mt-0",
        text: "Awaiting Response",
      },
      {
        name: "completed",
        label: "Complete",
        placeholder: "",
        type: "checkbox",
        cols: 6,
        col_md: 4,
        value: false,
        class: "pl-7 pr-7 pt-0 mb-0 pb-0 mt-0",
        text: "Complete",
      },

      {
        name: "review_request",
        label: "ReviewRequest",
        placeholder: "",
        type: "checkbox",
        cols: 6,
        col_md: 4,
        value: false,
        class: "pl-7 pr-7 pt-0 mb-0 pb-0 mt-0",
        text: "Review Request",
      },

      // {
      //   name: "client_responded",
      //   label: "ClientResponded",
      //   placeholder: "",
      //   type: "checkbox",
      //   cols: 6,
      //   col_md: 4,
      //   value: false,
      //   class: "pl-7 pr-7 pt-0 mb-0 pb-0 mt-0",
      //   text: "Responded",
      // },
      {
        name: "audit",
        label: "Audit",
        placeholder: "",
        type: "checkbox",
        cols: 6,
        col_md: 4,
        value: false,
        class: "pl-7 pr-7 pt-0 mb-0 pb-0 mt-0",
        text: "Audit",
      },
      {
        name: "rejected",
        label: "Rejected",
        placeholder: "",
        type: "checkbox",
        cols: 6,
        col_md: 4,
        value: false,
        class: "pl-7 pr-7 pt-0 mb-0 pb-0 mt-0",
        text: "Rejected",
      },
    ],
    inputFilterRequestTypeCheckboxValues: [],
    inputFilterRequestTypeCheckbox: [
      {
        name: "bills",
        label: "BILL",
        placeholder: "",
        type: "checkbox",
        cols: 6,
        col_md: 4,
        value: false,
        class: "pl-7 pr-7 pt-0 mb-0 pb-0 mt-0",
        text: "Bill",
      },
      // {
      //   name: "bank_transaction",
      //   label: "BANK TRANSACTION",
      //   placeholder: "",
      //   type: "checkbox",
      //   cols: 6,
      //   col_md: 4,
      //   value: false,
      //   class: "pl-7 pr-7 pt-0 mb-0 pb-0 mt-0",
      //   text: "Bank Transaction",
      // },
      {
        name: "invoices",
        label: "INVOICE",
        placeholder: "",
        type: "checkbox",
        cols: 6,
        col_md: 4,
        value: false,
        class: "pl-7 pr-7 pt-0 mb-0 pb-0 mt-0",
        text: "Invoice",
      },
      // {
      //   name: "expenses_claims",
      //   label: "EXPENCES CLAIM",
      //   placeholder: "",
      //   type: "checkbox",
      //   cols: 6,
      //   col_md: 4,
      //   value: false,
      //   class: "pl-7 pr-7 pt-0 mb-0 pb-0 mt-0",
      //   text: "Expenses Claim",
      // },
      // {
      //   name: "quotations",
      //   label: "QUOTATIONS",
      //   placeholder: "",
      //   type: "checkbox",
      //   cols: 6,
      //   col_md: 4,
      //   value: false,
      //   class: "pl-7 pr-7 pt-0 mb-0 pb-0 mt-0",
      //   text: "Quotation",
      // },
      // {
      //   name: "payroll_data",
      //   label: "PAYROLL DATA",
      //   placeholder: "",
      //   type: "checkbox",
      //   cols: 6,
      //   col_md: 4,
      //   value: false,
      //   class: "pl-7 pr-7 pt-0 mb-0 pb-0 mt-0",
      //   text: "Payroll Data",
      // },
      {
        name: "payment",
        label: "PAYMENT",
        placeholder: "",
        type: "checkbox",
        cols: 6,
        col_md: 4,
        value: false,
        class: "pl-7 pr-7 pt-0 mb-0 pb-0 mt-0",
        text: "Payment",
      },
      // {
      //   name: "salary",
      //   label: "SALARY",
      //   placeholder: "",
      //   type: "checkbox",
      //   cols: 6,
      //   col_md: 4,
      //   value: false,
      //   class: "pl-7 pr-7 pt-0 mb-0 pb-0 mt-0",
      //   text: "Salary",
      // },
      // {
      //   name: "voucher",
      //   label: "VOUCHER",
      //   placeholder: "",
      //   type: "checkbox",
      //   cols: 6,
      //   col_md: 4,
      //   value: false,
      //   class: "pl-7 pr-7 pt-0 mb-0 pb-0 mt-0",
      //   text: "Voucher",
      // },
      {
        name: "others",
        label: "OTHERS",
        placeholder: "",
        type: "checkbox",
        cols: 6,
        col_md: 4,
        value: false,
        class: "pl-7 pr-7 pt-0 mb-0 pb-0 mt-0",
        text: "Others",
      },
    ],

    statusCheckboxClear: false,
    requestCheckboxClear: false,
    dialog: false,
    applyMobileFilter: false,
  }),

  mounted() {
    this.clearFilterOnMount();
  },

  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
    }),
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      if (!this.applyMobileFilter) {
        this.clearAllValues();
      }
    },
    searchTicketNo() {
      this.$store.commit("ticket/setSearch", this.searchValue);
      if (this.$route.path !== "/transactions")
        this.$router.push({ path: "/transactions" });
      else EventBus.$emit("global-search");
    },
    clearFilterOnMount() {
      const obj = {
        dateRange: "",
        messageFilter: "",
        searchStatus: [],
        requestType: [],
      };
      this.$store.commit("ticket/setSearch", "");
      this.$store.commit("ticket/setAdvancedSearch", obj);
    },
    clearAllValues() {
      this.inputFilterStatusCheckboxValues = [];
      this.inputFilterRequestTypeCheckboxValues = [];
    },
    setDate(val) {
      this.inputList[0].model = val;
      let updatedArr = val
        .split("~")
        .map((item) => moment(item).format("DD MMM YYYY"));
      this.inputList[0].showDateFormat = updatedArr.join(" - ");
    },

    selectAllCheckboxes(array) {
      this.inputFilterStatusCheckboxValues = array.map((ele) => {
        ele.value = true;
        return ele.label;
      });
      this.statusCheckboxClear = true;
    },

    selectAllRequestCheckboxes(array) {
      this.inputFilterRequestTypeCheckboxValues = array.map((ele) => {
        ele.value = true;
        return ele.label;
      });
      this.requestCheckboxClear = true;
    },

    selectedBoxes(array) {
      var res = false;
      array.map((ele) => {
        ele.value == true ? (res = true) : "";
      });
      return res;
    },
    clearCheckboxes(array) {
      if (array == "status") {
        this.inputFilterStatusCheckboxValues = [];
        this.statusCheckboxClear = false;
      } else {
        this.inputFilterRequestTypeCheckboxValues = [];
        this.requestCheckboxClear = false;
      }
    },

    clearFilter() {
      this.$refs.textField1[0].resetTextFieldModel();
      this.inputList[0].model = "";
      this.inputFilterStatusCheckboxValues = [];
      this.inputFilterRequestTypeCheckboxValues = [];
      this.advancedSearch();
    },

    updateInput(val) {
      let index = this.inputFilterStatusCheckboxValues.findIndex(
        (item) => item === val
      );
      if (index == -1) this.inputFilterStatusCheckboxValues.push(val);
      else this.inputFilterStatusCheckboxValues.splice(index, 1);
    },

    updateRequest(val) {
      let index = this.inputFilterRequestTypeCheckboxValues.findIndex(
        (item) => item === val
      );
      if (index == -1) this.inputFilterRequestTypeCheckboxValues.push(val);
      else this.inputFilterRequestTypeCheckboxValues.splice(index, 1);
    },

    advancedSearch() {
      this.setPayload(
        this.inputList[0].model,
        this.messageInput[0].model,
        this.inputFilterStatusCheckboxValues,
        this.inputFilterRequestTypeCheckboxValues
      );
      this.applyMobileFilter = true;
      if (this.$route.path !== "/transactions")
        this.$router.push({ path: "/transactions" });
    },
    setPayload(
      inputList,
      messageInput,
      inputFilterStatusCheckboxValues,
      inputFilterRequestTypeCheckboxValues
    ) {
      const obj = {
        dateRange: inputList,
        messageFilter: messageInput,
        searchStatus: inputFilterStatusCheckboxValues,
        requestType: inputFilterRequestTypeCheckboxValues,
      };
      this.$store.commit("ticket/setAdvancedSearch", obj);
      EventBus.$emit("advance-search");
      setTimeout(() => {
        EventBus.$emit("advance-search-filters-data", obj);
      }, 100);
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.v-menu__content {
  top: 45px !important;
}
.header-search-input {
  width: 206px !important;
  min-width: 206px !important;
}
.input-placeholder-text {
  position: absolute;
  left: 25px;
  top: 9px;
}

.v-menu__content {
  display: inline-block;
  overflow: auto !important;
  height: 300px !important;
  min-height: 300px !important;
  background-color: #fff !important;
}
:deep(.v-input--selection-controls .v-input__slot > .v-label) {
  height: 30px !important;
}
:deep(.v-input--selection-controls) {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.clear-btn {
  border: 1px solid #e8ebf4 !important;
  border-radius: 8px !important;
}
.connect-btn {
  border-radius: 8px !important;
}

:deep(.v-input__icon--append .v-icon) {
  color: #fff !important;
  cursor: pointer;
  position: relative;
  left: 80% !important;
}

.myicon {
  cursor: pointer !important;
  left: 10px !important;
  top: 2px !important;
}
.cross-icon {
  border: 1px solid #e5e1e1;
  border-radius: 5px;
  font-size: 18px !important;
}

/* filters dialog style  */
:deep(.v-dialog) {
  background-color: #fff !important;
}
:deep(.checkbox-row) {
  margin-left: 0.5px !important;
  margin-right: 0.5px !important;
}
</style>

<template>
  <div>
    <v-container>
      <v-row class="d-flex justify-space-between">
        <v-col :cols="$vuetify.breakpoint.lgAndUp ? 6 : 12">
          <!-- :style="
            $vuetify.breakpoint.lg
              ? 'width: 50%; margin: auto !important'
              : 'width: 95%; margin: auto !important; margin-top:12%'
          " -->
          <v-card style="border-radius: 10px" class="elevation-0">
            <v-card-title class="d-flex justify-space-between pa-25 pb-4 pl-4">
              <p class="fs-18 mb-0 fw-600 f-inter">
                {{ createTicketFields.title }}
              </p>
            </v-card-title>
            <p
              v-if="currentPackage"
              class="ma-0 pa-0 mb-4 mx-4 fs-12 fw-500 f-inter subHeading--text"
            >
              {{ createTicketFields?.remainingRequestCount }} :
              {{ currentPackage?.noOfRequestsConsumed }} /
              {{ currentPackage?.requestAllocated }}
            </p>
            <v-row class="ma-1">
              <v-btn
                v-for="(button, index) in createTicketFields.requestButtons"
                :key="index + '1'"
                class="ml-3 mb-2 rounded-lg text-none"
                :class="
                  button.name == selectedButton
                    ? 'lightPurple whiteColor--text'
                    : ''
                "
                outlined
                color="lightPurple"
                :value="selectedButton"
                @click="setRequestType(button.name)"
                ><span class="fs-12">{{ button.name }}</span></v-btn
              >

              <FormInput
                class="rounded-lg"
                v-for="(input, index) in createTicketFields.inputs"
                :input="input"
                :key="index"
                @update-value="(n) => (input.model = n)"
                @update-file-input-value="(n) => (input.model = n)"
                @update-file-input-value-for-file="
                  updateInputValue(input, $event)
                "
                @on-enter="
                  pdfDialog.show ? createMutilpleTickets() : createTicket()
                "
              >
              </FormInput>
            </v-row>
            <v-row class="d-flex justify-end">
              <v-btn
                v-if="pdfDialog.show"
                id="v-step-15"
                class="btn btn-primary primary--text text-capitalize mr-7 mb-5"
                color="lightBackground"
                depressed
                :loading="pdfDialog.createMultipleTicketsLoader"
                @click="createMutilpleTickets()"
              >
                <span class="fw-600 fs-14 f-inter">Record Now</span>
              </v-btn>
              <v-btn
                v-else
                id="v-step-15"
                class="btn btn-primary primary--text text-capitalize mr-7 mb-5 rounded-lg"
                color="buttonBg"
                depressed
                :loading="loader"
                @click="createTicket()"
              >
                <span class="fw-600 fs-13 f-inter" color="lightPurple" style="letter-spacing: 0 !important"
                  >{{ createTicketFields.title }}
                </span>
              </v-btn>
            </v-row>
          </v-card>
        </v-col>
        <!-- <v-col :cols="$vuetify.breakpoint.lgAndUp ? 6 : 12"> </v-col> -->
        <PdfSplit
          v-if="pdfDialog?.show"
          :pdfDialog="pdfDialog"
          @selected-value="updateSelectedValue($event)"
        />
      </v-row>
    </v-container>
  </div>
</template>
<script>
import FormInput from "../common/FormInput.vue";
import axios from "axios";
import EventBus from "../../js/EventBus";
import UrlServices from "../../services/Url";
import ApiService from "../../services/Api";
import { mapGetters } from "vuex";
import { PDFDocument, PDFPage } from "pdf-lib";
import PdfSplit from "./PdfSplit.vue";
import Vue from "vue";
export default {
  components: { FormInput, PdfSplit },
  data() {
    return {
      loader: false,
      pdfDialog: {
        PDF_CREATION_LIMIT: 4,
        show: false,
        createMultipleTicketsLoader: false,
        totalPages: 0,
        pdfDoc: null,
        pdfBytes: null,
      },
      selectedButton: "Bill",
      selectedValueUpdated: {},
      createTicketFields: {
        show: true,
        title: "Record Now",
        remainingRequestCount: "Request Count",
        dialogType: "form",
        for: "create-ticket",
        requestButtons: [
          {
            name: "Bill",
          },
          {
            name: "Invoice",
          },
          {
            name: "Payment",
          },

          // {
          //   name: "Bank Statement",
          // },
        ],
        inputs: [
          // {
          //   value: "ticketType",
          //   label: "Request type *",
          //   placeholder: "Select request type",
          //   type: "select",
          //   options: [],
          //   default: "OTHERS",
          //   cols: 12,
          //   col_md: 12,
          //   maxLength: "50",
          //   model: "OTHERS",
          //   id: "v-step-11",
          // },
          {
            value: "currency",
            label: "Currency*",
            placeholder: "Select currency",
            type: "select",
            options: [],
            cols: 12,
            col_md: 12,
            default: "",
            maxLength: "50",

            model: "",
            id: "v-step-12",
          },
          {
            value: "file",
            label: "Select or Drag a file from computer",
            type: "file",
            cols: 12,
            col_md: 12,
            model: "",
            useFor: "PWA",
            id: "v-step-13",
          },
          {
            name: "description",
            label: "Prompt",
            placeholder: "Enter A Prompt to AI Here...",
            type: "textarea",
            cols: 12,
            col_md: 12,
            model: "",
            maxLength: "500",
            id: "v-step-14",
          },
          // {
          //     value: "record",
          //     custom_icon:require("@/assets/dashboard/record-icon.png"),
          //     label: "Record voice note",
          //     type: "text",
          //     cols:12,
          //     col_md:12,
          // }
        ],
      },
    };
  },
  async mounted() {
    await this.setCurrency();
    // await this.getTicketsType();
    this.listenBus();
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      token: "auth/getAuthToken",
      currentWorkspace: "auth/getCurrentWorkspace",
      paginate: "ticket/getPaginate",
      currentPackage: "auth/getPackage",
    }),
  },
  methods: {
    setRequestType(selectedButton) {
      console.log(selectedButton, "SB");
      this.selectedButton = selectedButton;
    },
    /*
     ** Function for resetting PDF Data
     */
    resetPdfData() {
      /*
       ** Reset Pdf Dialog Object
       */
      this.pdfDialog.show = false;
      this.pdfDialog.createMultipleTicketsLoader = false;
      this.pdfDialog.totalPages = 0;
      this.pdfDialog.pdfDoc = null;
      this.pdfDialog.pdfBytes = null;
    },
    /*
     ** Update Input value for pdf Files
     */
    async updateInputValue(fieldInput, file) {
      /*
       ** Update Input Model
       */
      fieldInput.model = file;

      if (file.type !== "application/pdf")
        if (this.pdfDialog.show) return this.resetPdfData();
        else return;

      this.resetPdfData();
      /*
       ** Get Pdf Total Pages
       */
      const { totalPages } = await this.getPdfPages();
      if (totalPages > 1) {
        this.pdfDialog.show = true;
        return;
      }
    },
    listenBus() {
      EventBus.$on("create-ticket-from-tour-guide", () => {
        this.createTicket();
      });
      EventBus.$on("add-description-from-tour-guide", () => {
        this.createTicketFields.inputs[2].model =
          "Ticket Created from Tour Guide";
      });
    },
    // getTicketsType() {
    //   ApiService.GetRequest(UrlServices.ticketTypes)
    //     .then((response) => {
    //       if (response.data.data.length > 0)
    //         this.createTicketFields?.inputs[0]?.options = [];
    //       response.data.data.forEach((item) => {
    //         // to set label with respect to design
    //         this.createTicketFields?.inputs[0]?.options.push({
    //           label: this.changeStringToSentenceCase(item.title),
    //           value: item.title,
    //           disable: item?.disable,
    //         });
    //       });
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
    changeStringToSentenceCase(item) {
      const arr = item.toLowerCase().split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      return arr.join(" ");
    },
    setCurrency() {
      this.user.currency.forEach((item) =>
        this.createTicketFields?.inputs[0]?.options?.push({
          label: item,
          value: item,
        })
      );
      this.createTicketFields.inputs[0].default = this.user.currency[0];
      this.createTicketFields.inputs[0].model = this.user.currency[0];
    },
    updateSelectedValue(value) {
      this.selectedValueUpdated = JSON.parse(JSON.stringify(value));
    },
    async getPdfPages() {
      const buff = await this.createTicketFields.inputs[1].model.arrayBuffer();
      const pdfBytes = new Uint8Array(buff);
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const totalPages = pdfDoc.getPages().length;
      this.pdfDialog.totalPages = totalPages;
      this.pdfDialog.pdfDoc = pdfDoc;
      this.pdfDialog.pdfBytes = pdfBytes;
      return { totalPages };
    },
    async createFiles() {
      const { pdfDoc } = this.pdfDialog;
      const outputValue = {};
      let addedPdfFile = [];
      let remainingFileCount = 0;
      for (let i = 0; i < Object.keys(this.selectedValueUpdated).length; i++) {
        if (!this.selectedValueUpdated[i].length) continue;
        let newPdfDoc = "";
        newPdfDoc = await PDFDocument.create();
        for (let j = 0; j < this.selectedValueUpdated[i].length; j++) {
          addedPdfFile.push(this.selectedValueUpdated[i][j]);
          let [newPage] = await newPdfDoc.copyPages(pdfDoc, [
            this.selectedValueUpdated[i][j] - 1,
          ]);

          newPdfDoc.addPage(newPage);
        }
        // Save the new PDF file
        const pdfBytes = await newPdfDoc.save();
        const blob = new Blob([pdfBytes], {
          type: "application/pdf",
        });
        remainingFileCount = remainingFileCount + 1;
        outputValue[i] = new File([blob], `extractedFile.pdf-${i}.pdf`);
      }
      /*
       ** Create output file for unselected file
       */
      for (let i = 1; i <= this.pdfDialog.totalPages; i++) {
        if (addedPdfFile.includes(i)) continue;
        let newPdfDoc1 = "";
        newPdfDoc1 = await PDFDocument.create();
        let [newPage1] = await newPdfDoc1.copyPages(pdfDoc, [i - 1]);
        newPdfDoc1.addPage(newPage1);
        const pdfBytes1 = await newPdfDoc1.save();
        const blob1 = new Blob([pdfBytes1], {
          type: "application/pdf",
        });
        outputValue[remainingFileCount] = new File(
          [blob1],
          `extractedFile-pdf-${remainingFileCount}.pdf`
        );
        remainingFileCount = remainingFileCount + 1;
      }
      return outputValue;
    },
    async createMutilpleTickets() {
      try {
        if (this.pdfDialog.createMultipleTicketsLoader) return;
        if (
          !(
            this.currentPackage?.requestAllocated -
              this.currentPackage?.noOfRequestsConsumed >=
            1
          )
        )
          return this.$showNotificationBar(
            "error",
            "Upgrade plan: Request limit exceeded."
          );
        this.pdfDialog.createMultipleTicketsLoader = true;
        const outputValue = await this.createFiles();
        if (Object.keys(outputValue).length === 0) {
          this.$showNotificationBar("error", "Selection has not been made");
          this.pdfDialog.createMultipleTicketsLoader = true;
          return;
        }
        for (let k = 0; k < Object.keys(outputValue).length; k++) {
          this.$showNotificationBar(
            "success",
            "Please wait, Conversion is in process."
          );
          let formData = new FormData();
          formData.append("requestType", this.selectedButton);
          // formData.append(
          //   "requestType",
          //   this.createTicketFields.inputs[0].model
          // );
          formData.append("currency", this.createTicketFields.inputs[0].model);
          formData.append("file", outputValue[k]);
          formData.append("message", this.createTicketFields.inputs[2].model);
          await ApiService.PostRequest(
            `${UrlServices.createTicket}${this.currentWorkspace.workspaceId}`,
            formData
          );
        }
        this.pdfDialog.createMultipleTicketsLoader = false;
        this.$showNotificationBar("success", "Successfully created");
        this.$store.commit("ticket/setPaginate", {
          itemsPerPage: 10,
          page: 1,
          lastPage:
            this.paginate.total + 1 < 10
              ? 1
              : Math.ceil((this.paginate.total + 1) / 10),
          total: this.paginate.total + 1,
        });
        this.$router.push({ path: "/transaction" });
        EventBus.$emit("clear-filters");
      } catch (error) {
        this.pdfDialog.createMultipleTicketsLoader = false;
        let errMessage = error.response.data.message
          ? error.response.data.message
          : error;
        this.$showNotificationBar("error", errMessage);
      }
    },
    async createTicket() {
      if (this.loader) return;
      if (
        !(
          this.currentPackage?.requestAllocated -
            this.currentPackage?.noOfRequestsConsumed >=
          1
        )
      )
        return this.$showNotificationBar(
          "error",
          "Upgrade plan: Request limit exceeded."
        );
      if (
        (!this.user.country || this.user.country === "false") &&
        this.user.role === "client"
      )
        return this.$showNotificationBar(
          "error",
          "Please add the country name of this user"
        );
      if (this.createTicketFields.inputs[1].model) {
        if (this.createTicketFields.inputs[2].model.size / (1024 * 1024) > 5) {
          return this.$showNotificationBar(
            "error",
            "Please provide less size attachment"
          );
        }
      }
      if (
        // !this.createTicketFields.inputs[0].model ||
        !this.selectedButton ||
        !this.createTicketFields.inputs[0].model ||
        !this.createTicketFields.inputs[1].model
      ) {
        return this.$showNotificationBar(
          "error",
          "Please provide all the required fields"
        );
      }
      if (
        this.createTicketFields.inputs[1].model
        // ||
        // this.createTicketFields.inputs[2].model.trim()
      ) {
        this.loader = true;
        let formData = new FormData();
        formData.append("requestType", this.selectedButton);
        // formData.append("requestType", this.createTicketFields.inputs[0].model);
        formData.append("currency", this.createTicketFields.inputs[0].model);
        formData.append("file", this.createTicketFields.inputs[1].model);
        formData.append("message", this.createTicketFields.inputs[2].model);
        ApiService.PostRequest(
          `${UrlServices.createTicket}${this.currentWorkspace.workspaceId}`,
          formData
        )
          .then((res) => {
            this.loader = false;
            this.$showNotificationBar("success", "Successfully created");
            this.$store.commit("ticket/addNewTicketinTickets", res.data.data);
            this.$store.commit("ticket/setPaginate", {
              itemsPerPage: 10,
              page: 1,
              lastPage:
                this.paginate.total + 1 < 10
                  ? 1
                  : Math.ceil((this.paginate.total + 1) / 10),
              total: this.paginate.total + 1,
            });
            this.$router.push({ path: "/transaction" });
            EventBus.$emit("clear-filters");
            // this.$emit("close-popup");
          })
          .catch((e) => {
            const message = e?.response?.data?.message
              ? e?.response?.data?.message
              : "Error Occurred";
            this.$showNotificationBar("error", message);
            this.loader = false;
          });
      } else {
        return this.$showNotificationBar(
          "error",
          "File attachment is required"
        );
      }
    },
  },
  beforeDestroy() {
    EventBus.$off("create-ticket-from-tour-guide");
    EventBus.$off("add-description-from-tour-guide");
  },
};
</script>
<style scoped>
.request-button {
  height: 35px !important;
  border-radius: 8px !important;
}
</style>

<template>
  <v-toolbar flat dense class="static-navbar">
    {{ tab }}
    <p
      class="mx-0 cursor-pointer"
      depressed
      v-for="(item, index) in items"
      :key="index"
    >
      <!-- @click="setStorage(item)" -->
      <router-link
        v-if="!item.auth || user.role === 'client'"
        :to="fetchingStatus && item.title == 'Reports' ? '' : item.to"
        :id="item?.id"
        class="remove-underline fw-500 f-inter fs-14"
        :style="
          fetchingStatus && item.title == 'Reports'
            ? 'cursor: not-allowed !important;'
            : ''
        "
        :class="
          $route.name == item.routeName
            ? 'whiteColor--text lightPurple'
            : 'black--text'
        "
        style="
          padding: 6px 10px 6px 10px !important;
          border-radius: 6px !important;
        "
      >
        {{ item.title }}
      </router-link>
    </p>
  </v-toolbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Navbar",

  data() {
    return {
      parentRoute: "",
      items: [
        {
          title: "Dashboard",
          to: "/dashboard",
          routeName: "Dashboard",
          auth: false,
          active: false,
          id: "v-step-3",
        },
        {
          title: "Record Now",
          to: "/generate-request",
          routeName: "GenerateRequests",
          auth: false,
          active: false,
          id: "v-step-10",
        },
        {
          title: "Transactions",
          to: "/transaction",
          // to: "/transactions",
          routeName: "Transactions",
          // routeName: "Transactions",
          auth: false,
          active: false,
          id: "v-step-1",
        },
        {
          title: "Reports",
          to: "/reports",
          // to: "/overview",
          routeName: "Reports",
          // routeName: "Overview",
          auth: true,
          active: false,
          disabled: true,
          id: "v-step-6",
        },
        {
          title: "Integration",
          to: "/integration",
          routeName: "Integration",
          auth: true,
          active: false,
          id: "v-step-9",
        },
      ],
    };
  },
  mounted() {
    let route = localStorage.getItem("prevRoute");
    if (this.$route.name != route) {
      localStorage.removeItem("prevRoute");
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      fetchingStatus: "dashboard/getFetchingStatus",
    }),
    tab() {
      let route = localStorage.getItem("prevRoute");
      this.items.filter((item) => {
        if (item.routeName == route) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
      return;
    },
  },
  watch: {
    parentRoute(newName) {
      console.log(newName, "NAME");
    },
  },

  methods: {
    setStorage(item) {
      localStorage.removeItem("prevRoute");
      this.items.filter((route) => {
        if (route.routeName == item.routeName) {
          route.active = true;
        } else {
          route.active = false;
        }
      });
    },
  },
};
</script>

<style scoped>
/* .border { */
/* border-bottom: 3px solid var(--v-lightPurple-base); */
/* background: #0000 !important; */
/* border-bottom: 3px solid var(--v-primary-base); */
/* padding-bottom: 15px; */
/* } */
.static-navbar {
  padding-top: 15px;
  /* background-color: #0000 !important; */
  /* background-color: transparent !important; */
}
.static-navbar >>> .v-toolbar__content {
  padding: 0 !important;
}
.disabled-button {
  pointer-events: none !important;
  cursor: no-drop !important;
}
</style>
